import {gql} from './__generated__/gql';

export const updateUser = gql(/* GraphQL */ `
  mutation UpdateUser($input: UpdateUserInput) {
    updateUser(input: $input) {
      id
      firstName
      lastName
      email
      phoneNumber
      kastaName
      referrerCode
      createdAt
      updatedAt
      account {
        balances {
          BTC
          ETH
          USDT
          KASTA
          POL
          SOL
          USDC
          BNB
          BUSD
          ADA
        }
      }
      deviceToken
      deviceType
      deviceAppInstanceId
    }
  }
`);

export const sendSignInEmail = gql(/* GraphQL */ `
  mutation SendSignInEmail($input: SendSignInEmailInput) {
    sendSignInEmail(input: $input)
  }
`);

export const sendSignInCodeEmail = gql(/* GraphQL */ `
  mutation SendSignInCodeEmail($input: SendSignInCodeEmailInput) {
    sendSignInCodeEmail(input: $input)
  }
`);

export const validateSignInCode = gql(/* GraphQL */ `
  mutation ValidateSignInCode($input: ValidateSignInCodeInput) {
    validateSignInCode(input: $input) {
      customToken
    }
  }
`);

export const updatePin = gql(/* GraphQL */ `
  mutation UpdatePin($input: UpdatePinInput) {
    updatePin(input: $input)
  }
`);

export const startRecoveryPinFlow = gql(/* GraphQL */ `
  mutation StartRecoveryPinFlow {
    startRecoveryPinFlow
  }
`);

export const validateRecoveryPin = gql(/* GraphQL */ `
  mutation ValidateRecoveryPin($input: ValidateRecoveryPinInput!) {
    validateRecoveryPin(input: $input) {
      attemptsLeft
      lockoutEnds
      timeoutEnds
      valid
    }
  }
`);

export const resetPin = gql(/* GraphQL */ `
  mutation ResetPin($input: ResetPinInput) {
    resetPin(input: $input)
  }
`);

export const deleteUser = gql(/* GraphQL */ `
  mutation DeleteUser {
    deleteUser
  }
`);

export const transfer = gql(/* GraphQL */ `
  mutation Transfer($input: TransferInput) {
    transfer(input: $input) {
      id
      parentId
      counterPartyUser {
        id
        firstName
        lastName
        email
        phoneNumber
        kastaName
      }
      type
      entryType
      status
      source {
        type
        id
      }
      dest {
        type
        id
      }
      sourceCurrency
      sourceAmount
      destCurrency
      destAmount
      fees
      createdAt
      exchangeRate
      details
      usdExchangeRate
      sourceCurrencyUsdExchangeRate
      destCurrencyUsdExchangeRate
    }
  }
`);

export const cashTransfer = gql(/* GraphQL */ `
  mutation CashTransfer($input: CashTransferInput) {
    cashTransfer(input: $input) {
      expiresAt
      redirectUrl
    }
  }
`);

export const generateDepositAddress = gql(/* GraphQL */ `
  mutation generateDepositAddress($input: GenerateDepositAddressInput) {
    generateDepositAddress(input: $input) {
      assetId
      address
    }
  }
`);

export const withdraw = gql(/* GraphQL */ `
  mutation Withdraw($input: WithdrawInput) {
    withdraw(input: $input) {
      id
      parentId
      counterPartyUser {
        id
        firstName
        lastName
        email
        phoneNumber
        kastaName
      }
      type
      entryType
      status
      source {
        type
        id
      }
      dest {
        type
        id
      }
      sourceCurrency
      sourceAmount
      destCurrency
      destAmount
      fees
      createdAt
      exchangeRate
      details
      usdExchangeRate
      sourceCurrencyUsdExchangeRate
      destCurrencyUsdExchangeRate
    }
  }
`);

export const cashWithdraw = gql(/* GraphQL */ `
  mutation CashWithdraw($input: CashWithdrawInput) {
    cashWithdraw(input: $input) {
      expiresAt
      redirectUrl
    }
  }
`);

export const swap = gql(/* GraphQL */ `
  mutation swap($input: SwapInput) {
    swap(input: $input) {
      id
      parentId
      counterPartyUser {
        id
        firstName
        lastName
        email
        phoneNumber
        kastaName
      }
      type
      entryType
      status
      source {
        type
        id
      }
      dest {
        type
        id
      }
      sourceCurrency
      sourceAmount
      destCurrency
      destAmount
      fees
      createdAt
      exchangeRate
      details
      usdExchangeRate
      sourceCurrencyUsdExchangeRate
      destCurrencyUsdExchangeRate
      redirectUrl
    }
  }
`);

export const logAppEvent = gql(/* GraphQL */ `
  mutation LogAppEvent($input: LogAppEventInput) {
    logAppEvent(input: $input)
  }
`);

export const startTierLockup = gql(/* GraphQL */ `
  mutation StartTierLockup($kastaInsertedAmount: Float, $level: Int!, $pin: String) {
    startTierLockup(input: {kastaInsertedAmount: $kastaInsertedAmount, level: $level, pin: $pin})
  }
`);

export const initiateSignup = gql(/* GraphQL */ `
  mutation initiateSignup($input: InitiateSignupInput) {
    initiateSignup(input: $input) {
      id
      email
      pin
      firstName
      lastName
      kastaName
      address {
        countryCode
      }
      referredBy
    }
  }
`);

export const setUserPin = gql(/* GraphQL */ `
  mutation setPin($input: SetPinInput) {
    setPin(input: $input) {
      pin
    }
  }
`);

export const setPersonalDetails = gql(/* GraphQL */ `
  mutation setPersonalDetails($input: SetPersonalDetailsInput) {
    setPersonalDetails(input: $input) {
      firstName
      lastName
      dateOfBirth
    }
  }
`);

export const initiateCashActivation = gql(/* GraphQL */ `
  mutation InitiateCashActivation($input: CashActivationInput) {
    initiateCashActivation(input: $input) {
      expiresAt
      redirectUrl
    }
  }
`);

export const claimCompletedTierLockup = gql(/* GraphQL */ `
  mutation ClaimCompletedTierLockup($input: ClaimCompletedTierLockupInput) {
    claimCompletedTierLockup(input: $input) {
      id
    }
  }
`);

export const generate2faOTP = gql(/* GraphQL */ `
  mutation Generate2faOTP {
    generate2faOTP {
      otpAuthUrl
    }
  }
`);

export const disable2faOTP = gql(/* GraphQL */ `
  mutation Disable2faOTP($input: Validate2faOTPInput) {
    disable2faOTP(input: $input)
  }
`);

export const validate2faOTP = gql(/* GraphQL */ `
  mutation Validate2faOTP($input: Validate2faOTPInput) {
    validate2faOTP(input: $input)
  }
`);

export const verify2faOTP = gql(/* GraphQL */ `
  mutation Verify2faOTP($input: Validate2faOTPInput) {
    verify2faOTP(input: $input)
  }
`);

export const activateCard = gql(/* GraphQL */ `
  mutation ActivateCard($input: CardActivationInput) {
    activateCard(input: $input) {
      expiresAt
      redirectUrl
    }
  }
`);

export const orderCard = gql(/* GraphQL */ `
  mutation OrderCard($input: OrderCardInput) {
    orderCard(input: $input) {
      brand
      expMonth
      expYear
      id
      last4
      status
      type
      id
      displayName
      brand
      type
      status
      expMonth
      expYear
      last4
      feesTransactionIds
    }
  }
`);

export const resetCardPin = gql(/* GraphQL */ `
  mutation ResetCardPin($input: ResetCardPinInput) {
    resetCardPin(input: $input) {
      expiresAt
      redirectUrl
    }
  }
`);

export const updateCardStatus = gql(/* GraphQL */ `
  mutation UpdateCardStatus($input: UpdateCardStatusInput) {
    updateCardStatus(input: $input)
  }
`);

export const addPaymentMethod = gql(/* GraphQL */ `
  mutation AddPaymentMethod($input: AddPaymentMethodInput) {
    addPaymentMethod(input: $input) {
      expiresAt
      redirectUrl
      token
      consentId
    }
  }
`);

export const chargePaymentMethod = gql(/* GraphQL */ `
  mutation ChargePaymentMethod($input: ChargePaymentMethodInput) {
    chargePaymentMethod(input: $input) {
      expiresAt
      redirectUrl
      token
      consentId
    }
  }
`);

export const deletePaymentMethod = gql(/* GraphQL */ `
  mutation DeletePaymentMethod($input: DeletePaymentMethodInput) {
    deletePaymentMethod(input: $input)
  }
`);

export const confirmCardTopUp = gql(/* GraphQL */ `
  mutation ConfirmCardTopUp($input: ConfirmCardTopUpInput) {
    confirmCardTopUp(input: $input) {
      transactionId
    }
  }
`);
